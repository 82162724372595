/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "theme/variables";

$fa-font-path: "../node_modules/font-awesome/fonts";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";

// Import styles for emoji picker component
@import '@ctrl/ngx-emoji-mart/picker';

// This must be imported for dx to work
@import "devextreme/dist/css/dx.common.css";
// Import desired theme here
// e.g change to dx.dark.css or dx.material.teal.dark.css...
@import "devextreme/dist/css/dx.softblue.css";

@import "theme/base/fonts";
@import "theme/base/typography";

@import "theme/form/button";
@import "theme/form/input";

:host,
body,
body * {
    font-family: $font-family;
}

body {
    font-weight: normal;
    color: $color-primary-black;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

    // Prevent black outline in Chrome
*, *:focus, *:hover {
    outline: none;
}

// region Ionic component overrides

// Prevent hiding loading spinner
html:not(.hydrated) body {
    display: unset !important;
}

p {
    font-size: 14px;
    color: $color-secondary-grey;
    margin: 0 0 25px 0;
}

ion-header {
    ion-button {
        --color: white !important;
    }

    ion-toolbar {
        --background: #{$color-primary-green};
    }
}

ion-back-button {
    --color: white !important;
}

ion-menu-button {
    --color: white !important;
}

ion-title {
    --color: white;
}

ion-title.md {
    font-size: 18px;
}

ion-item {
    --background: white;
}

.header-md::after {
    content: none;
}

.app-ion-loading {
    .loading-wrapper {
        background: #171719 !important;
        border: 1px solid $color-primary-grey;
        box-shadow: 0 6px 12px rgba(0, 0, 0, .25) !important;
        font-weight: normal;
    }
}

// endregion

// region DevExtreme overrides

.dx-field, .dx-list-item, .dx-texteditor-input {
    font-size: $font-size-web-normal;
}

.dx-header-row {
    .dx-datagrid-text-content {
        font-size: $font-size-web-normal;
    }
}

dx-text-box, dx-number-box, dx-select-box, dx-text-area, dx-date-box, dx-button {
    font-size: $font-size-web-normal !important;
}

.dx-placeholder {
    font-size: $font-size-web-normal;
}

.mobile {
    .dx-field, .dx-list-item, .dx-field-item-label-text, .dx-texteditor-input {
        font-size: $font-size-mobile-normal;
    }

    .dx-header-row {
        .dx-datagrid-text-content {
            font-size: $font-size-mobile-normal;
        }
    }

    .dx-data-row {
        font-size: $font-size-mobile-normal;
    }

    dx-text-box, dx-number-box, dx-select-box, dx-text-area, dx-date-box, dx-button {
        font-size: $font-size-mobile-normal !important;
    }

    .dx-placeholder {
        font-size: $font-size-mobile-normal;
    }
}

.dx-widget, .dx-widget-input, .dx-menu-base, .dx-texteditor-input {
    font-family: $font-family !important;
}

.dx-widget {
    font-size: 16px;
}

.dx-field:last-of-type {
    margin: 0 0 10px 0;
}

.dx-field-item-label {
    font-size: $font-size-web-normal;
    font-weight: bold;
    padding: 8px 15px 9px 0;
}

.dx-field-label,
.dx-field-item-label-text {
    color: $color-list-font !important;
}

.dx-field-item-label-location-right .dx-field-item-label-text {
    font-weight: normal;
}

.dx-toolbar .dx-texteditor {
    width: calc(100% - 3px) !important;
}

.dx-toolbar .dx-toolbar-after {
    padding-left: 0;
    width: 100%;
}

.dx-datebox-wrapper-calendar {
    .dx-toolbar-after {
        padding-left: 0 !important;
        width: unset;
    }

    .dx-datebox-datetime-time-side {
        background-color: white !important;
    }

    .dx-texteditor-input {
        padding: 6px 8px !important;
    }
}

.dx-datagrid-search-panel {
    margin-left: 0;
}

.dx-datagrid-rowsview {
    border-top: none !important;
}

.dx-datagrid {
    .dx-datagrid-headers {
        .dx-row {
            td.dx-pointer-events-none {
                border-right-width: 1px;
            }
        }
    }

    .dx-datagrid-rowsview {
        .dx-freespace-row, .dx-data-row {
            td.dx-pointer-events-none {
                border-right-width: 1px;
            }
        }
    }
}

.dx-datagrid-edit-popup {
    .dx-overlay-content {
        background-color: $color-page-background;
    }

    .dx-datagrid-edit-form-item {
        align-items: unset !important;
    }

    .dx-fileuploader-input-wrapper:before {
        padding: 0;
    }

    .dx-toolbar-items-container {
        .dx-toolbar-after {
            display: flex;
            flex-direction: row-reverse;

            .dx-toolbar-button {
                padding-right: 15px;

                .dx-button {
                    height: 40px;
                    padding: 0 20px;
                    margin-right: 20px;
                    margin-left: 10px;
                    border: none;
                    border-radius: 20px;

                    .dx-button-content {
                        line-height: 20px;

                        .dx-button-text {
                            line-height: 20px;
                        }
                    }
                }
            }

            .dx-toolbar-button:first-of-type {
                .dx-button {
                    background-color: $color-primary-green;
                    border-color: transparent;

                    .dx-button-content {
                        .dx-button-text {
                            color: $color-primary-background !important;
                        }
                    }
                }
            }

            .dx-toolbar-button.dx-state-hover {
                background-color: $color-hover !important;
            }
        }
    }
}

.dx-datagrid-focus-overlay {
    border-color: transparent !important;
}

// Otherwise the "display: table" breaks the layout on iOS
.dx-field:before {
    display: initial;
}

.dx-field-label {
    width: 100% !important;
}

.dx-field-value {
    width: 100% !important;
}

.dx-editor-outlined.dx-state-readonly,
.dx-editor-filled.dx-state-readonly {
    border-style: solid !important;
}

.dx-editor-underlined.dx-state-readonly {
    border-bottom-style: solid !important;
}

.dx-editor-underlined.dx-state-readonly.dx-state-focused {
    border-color: #e8eaeb;
}

.dx-tag {
    align-self: center;
}

.dx-form {
    margin: 15px;

    .dx-field-item-content {
        background: transparent !important;
    }

    .dx-textbox, .dx-numberbox {
        border-radius: $form-item-border-radius !important;
    }

    .dx-texteditor-container {
        border-radius: $form-item-border-radius !important;
    }

    .dx-texteditor-input {
        color: $color-list-font;
        background-color: $color-editor-background !important;
        border: none !important;
    }

    .dx-texteditor.dx-editor-outlined {
        border: none !important;
        background-color: $color-editor-background;
    }

    .drop-down-box {
        border: none !important;
    }

    .dx-dropdowneditor-button {
        background-color: $color-editor-background;
    }

    .dx-clear-button-area {
        background-color: $color-editor-background;
    }

    .dx-layout-manager > .dx-collection > .dx-collection > .dx-box-item > .dx-box-item-content > .dx-box-item:first-child {
        // padding-right: 100px;
    }
}

.form-column-without-padding {
    .dx-layout-manager > .dx-collection > .dx-collection > .dx-box-item > .dx-box-item-content > .dx-box-item:first-child {
        padding-right: 0;
    }
    .dx-layout-manager .dx-field-item:not(.dx-first-col),
    .dx-layout-manager .dx-field-item:not(.dx-last-col) {
        padding: 0 !important;
    }

    .dx-layout-manager .dx-field-item:not(.dx-first-col) {
        padding-left: 16px !important;
    }

    .dx-box-item .dx-box-item {
        margin-bottom: 0 !important;
    }
}

.form-caption-without-padding {
    .dx-form-group-caption {
        padding-left: 47px;
    }
}

dx-form[ng-reflect-read-only="true"] {
    .dx-field-item-required-mark {
        display: none;
    }
}

.dx-toast-message {
    white-space: pre-line;
}

.dx-toast-content {
    border-radius: 0;
}

.dx-toast-info {
    background-color: $color-primary-green;
}

.toast-md, .toast-ios {
    .dx-toast-icon {
        width: 20px;
        height: 20px;
    }
}

.toast-md {
    .dx-toast-content {
        width: 300px !important;
        border-radius: 50px;
    }
}

.toast-ios {
    .dx-toast-content {
        border-radius: 8px;
    }
}

.dx-datagrid-column-chooser {
    .dx-toolbar-before {
        width: 100%;
    }

    .dx-toolbar-after {
        width: unset;
    }
}

.dx-scheduler .dx-loadpanel-content {
    display: none;
}

.dx-fieldset-header,
.dx-form-group-caption {
    font-size: 20px;
    font-size: $font-size-web-header;
    font-weight: 600;
}

.dx-fieldset-header {
    margin: 0;
}

.dx-form-group-with-caption > .dx-form-group-content {
    padding-top: 32px;
    margin-top: 0;
    border-top: 0;
    padding-bottom: 20px;
}

.dx-form-group-with-caption .dx-form-group.dx-form-group-with-caption {
    padding-left: 0;
}

// Belongs to the header, but it must be here in order to work
.toolbar-menu {
    .dx-submenu {
        .dx-menu-item {
            font-size: $font-size-web-normal;
        }
    }
}

.dx-checkbox.dx-state-readonly {
    border: 1px solid #e8eaeb;
}

.dx-checkbox-checked {
    .dx-checkbox-icon {
        color: $color-primary-green;
    }
}

.dx-datagrid {
    .dx-link {
        color: $color-primary-text;
    }
}

.dx-list .dx-empty-message, .dx-list-item {
    color: $color-list-font !important;
}

.dx-data-row {
    color: $color-list-font !important;
}

.dx-list-item.dx-state-active {
    background-color: $color-primary-green !important;
}

.select-box-list-popup .dx-popup-content {
    padding: 0 !important;
}

.select-box-popup, .select-list-popup {
    background: $color-page-background !important;
}

.select-box-popup .dx-datagrid-headers {
    border-bottom: 1px solid #e7e7e7;

    td {
        color: #1f2125 !important;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        padding: 14px 7px 17px 7px !important;
    }
}

.mobile {
    .dx-selectbox-popup-wrapper {
        .dx-list-item {
            height: 40px;

            .dx-list-item-content {
                vertical-align: middle;
            }
        }
    }
}

.dx-dropdowneditor-overlay {
    .dx-popup-content {
        padding: 10px;
    }
}

.dx-selectbox-popup-wrapper {
    .dx-list-item {
        height: 50px;

        .dx-list-item-content {
            vertical-align: middle;
        }
    }
}

.dx-dropdowneditor-overlay, .dx-selectbox-popup-wrapper {
    .dx-overlay-content {
        border-radius: 8px;
    }
}

.dx-tooltip-wrapper {
    pointer-events: none;

    .dx-popup-content {
        background-color: black !important;
        border-radius: 4px;
    }
}

// Fix incorrectly aligned form items in safari
.dx-layout-manager {
    .dx-label-h-align.dx-flex-layout:not(.dx-field-item-label-align) {
        align-items: center;
    }
}

.no-padding-group {
    .dx-form-group-content {
        padding: 0 !important;
    }
}

// endregion


// region Page

.page-container {
    height: 100%;
    display: flex;
    flex-direction: row;

    .page-container-list {
        width: 40%;
        border-right: 1px solid $color-primary-green;
    }

    .page-container-details {
        width: 60%;
    }
}

.page-content {
    width: 100%;
    margin-top: 6px;
}

.list-page-content {
    @extend .page-content;
    height: calc(100% - #{$page-title-container-height} - #{$page-title-container-margin} - 2 * #{$list-page-padding});
    width: 100%;
    margin: 0 $list-page-padding $list-page-padding 0;
}

.list-page-content-mobile {
    width: 100%;
    height: 100%;
    padding: 0 5px 5px 5px;
}

.list-grid {
    height: 100%;
    background: $color-page-background;
}

.page-title-container {
    height: $page-title-container-height;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 0;
    margin: 0;
    padding: 0 32px;

    .page-title-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        padding: 0 40px;
    }
}

.page-title-container-popup {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: $page-title-container-height;
}

.page-back-button {
    border: none;
    margin-right: 40px !important;
    margin-left: 35px !important;
    background-color: transparent;

    .dx-button-content {
        .dx-icon {
            color: $color-primary-green !important;
            font-size: 30px !important;
        }
    }

    div {
        display: flex;
        align-items: center;
        padding: 15px !important;
        font-weight: 700;

        i {
            font-size: 28px !important;
        }
    }
}

.page-header-right-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    margin-left: 20px;

    & > * {
        margin-left: 10px;
    }
}

.page-header-right-button {
    min-width: 200px;
}

.delete-button {
    background-color: #d9534f;
    margin-right: 85px !important;

    .dx-button-content {
        .dx-icon {
            color: white !important;
        }

        .dx-button-text {
            color: white !important;
        }
    }
}

.page-header-close-button {
    margin-left: 5px;
    margin-right: 15px;
    border: none;
    background-color: $color-page-background;

    div > i {
        font-size: 18px !important;
    }
}

.page-title {
    font-size: $font-size-detail-title;
    font-weight: 600;
}

.page-title-detail {
    @extend .page-title;
    margin-left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
        display: flex;
        &:not(:last-child) {
            position: relative;
            padding-right: 50px;
            color: $color-primary-grey;

            a {
                position: relative;
                z-index: 10;
                color: $color-primary-grey;
                text-decoration: none;
            }

            &::after {
                position: absolute;
                top: 50%;
                right: 20px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 5px;
                height: 10px;
                background: transparent url(/assets/svg/title-separator-icon.svg) no-repeat center center / contain;
                content: '';
            }
        }
    }
}

$page-content-border-top: 1px;
$page-content-margin-top: 6px;
$page-content-margin: 15px;
$page-content-margin-left: 20px;

.detail-page-content {
    @extend .page-content;
    height: calc(100% - #{$page-title-container-height} - #{$page-title-container-margin} - #{$page-content-border-top + $page-content-margin-top} - #{$form-button-container-padding-top} - #{$page-content-margin});
    width: 100%;
    margin: $page-content-margin 0 $page-content-margin 0;
}

.detail-page-content-mobile {
    margin-right: 15px;
    height: calc(100% - #{$form-button-container-padding-top});
}

// endregion

// region Form

.detail-form {
    height: 100%;
}

.form-container {
    width: calc(100% - 30px);
    background: $color-page-background;

    .dx-button-text {
        font-size: $font-size-web-normal;
        color: $color-primary-text !important;
    }
}

.dx-button-content {
    .dx-button-text {
        color: $color-primary-background !important;
    }
}

.dx-scrollable-scroll-content {
    background-color: #bbbbbb !important;
}

.app-form-select-list-item {
    align-items: flex-start !important;

    .dx-field-item-label {
        padding-bottom: 10px;
    }
}

.detail-grid-item {
    align-items: normal !important;
    border-bottom: none !important;

    .dx-field-item {
        border-bottom: none !important;
    }
}

.file-uploader-item {
    @extend .detail-grid-item;
}

.detail-list-item {
    align-items: unset !important;

    .dx-list {
        border: 1px solid #e8eaeb;
        border-radius: 2px;

        ::ng-deep .dx-list-static-delete-button {
            border: none;
        }

        .dx-scrollable-wrapper, .dx-scrollable-container {
            max-height: $detail-list-height;
        }
    }
}

.form-button-container {

}

.detail-list {
    $list-item-height: 77px;

    .dx-list-item {
        height: $list-item-height;

        .dx-list-item-content {
            padding-left: 20px;
        }
    }

    .dx-list-item.dx-state-hover:before {
        background: $color-primary-green;
    }

    .dx-list-item.dx-state-hover {
        background-color: transparent !important;
        color: $color-primary-green !important
    }

    .dx-list-item.dx-state-focused {
        background-color: $color-primary-green !important;
    }

    .dx-list-item.dx-state-hover {
        color: $color-list-font !important;
    }
}

.unit-label {
    line-height: 39px;
    font-size: 16px;
}

.select-box-container {
    position: relative;
}

.detail-button {
    background: transparent !important;
    border: none !important;
    position: absolute;
    right: 60px;
    top: calc(50% - 17px);
    height: 34px;

    .dx-button-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;

        .dx-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-primary-black !important;

            &:before {
                display: block;
                width: 15px;
                height: 15px;
                content: '';
                background: transparent url('/assets/svg/arrow-right-icon.svg') no-repeat center center / contain;
            }
        }

        .dx-button-text {
            color: $color-list-font !important;
            font-size: $font-size-web-normal;
        }
    }
}

.detail-button-readonly {
    right: 20px;
}

.dx-icon-column-chooser {
    &:before {
        display: block;
        width: 20px;
        height: 20px;
        background: transparent url(/assets/svg/column-chooser-icon.svg) no-repeat center center / contain;
        content: '';
    }
}

// endregion

// region Mobile

$grid-row-mobile-height: 65px;
$grid-row-mobile-padding-start: 12px;
$grid-row-mobile-padding-end: 6px;

.grid-row-mobile {
    tr {
        height: $grid-row-mobile-height;
    }

    .row-item {
        height: $grid-row-mobile-height;
        --min-height: #{$grid-row-mobile-height};
        --padding-start: 0;
        --inner-padding-end: #{$grid-row-mobile-padding-end};

        .row-content-container {
            width: 100%;
            height: 65%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: $grid-row-mobile-padding-start;

            .row-content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: 14px;

                .row-content-title {
                    font-weight: 600;
                }

                div {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                div:first-child:not(:only-child) {
                    max-width: 60%;
                }

                div:nth-child(2) {
                    max-width: 40%;
                }
            }

            .row-content:first-child {
                font-size: 16px;
            }
        }
    }
}

$grid-row-mobile-single-height: 45px;

.grid-row-mobile-single {
    tr {
        height: $grid-row-mobile-single-height;
    }

    .row-item {
        height: $grid-row-mobile-single-height;
        --min-height: #{$grid-row-mobile-single-height};

        .row-content-container {
            justify-content: center;
        }
    }
}

.mobile {
    .dx-scrollable-scrollbar {
        display: none !important;
    }
}

// endregion

// region Common styles

.hide-element {
    display: none;
}

.align-left-column {
    text-align: left !important;
}

.grabbable {
    /*! autoprefixer: off */
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
    /*! autoprefixer: off */
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

.dx-layout-manager .dx-field-item:not(.dx-last-col) {
    padding-left: 47px;
    padding-right: 47px;
}

.dx-layout-manager .dx-field-item:not(.dx-first-col) {
    padding-left: 47px;
    padding-right: 47px;
}

dx-form > .dx-layout-manager {
    margin-left: -28px;
    margin-right: -28px;
}

.dx-fileuploader-input-wrapper {
    .dx-button {
        border: 0;
    }

    .dx-button-content {
        padding: 10px 0;
    }

    .dx-button-text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: normal !important;

        &:before {
            display: inline-block;
            content: '';
            width: 20px;
            height: 20px;
            background: transparent url(/assets/svg/modify-icon.svg) no-repeat center center / contain;
            margin-right: 10px;
        }
    }
}

.dx-fileuploader-input-container {
    display: none !important;
}

.fileuploader-buttons {
    display: flex;
    justify-content: space-between;

    .dx-button {
        &.dx-state-focused,
        &:hover {
            background: transparent !important;
        }
    }

    .file-uploader-delete-button{
        margin: 0 !important;

        .dx-button-content {
            padding: 0 !important;
        }
    }
}

.dx-datagrid-rowsview {
    .dx-datagrid-checkbox-size {
        float: left;
        margin: 8px 0 0 20px;
    }
}

.dx-editor-cell {
    .dx-texteditor-input {
        font-size: 12px;
    }
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable {
    z-index: 1000;
}

.searchbar {
    width: 250px;
    padding: 0;

    .sc-ion-searchbar-ios {
        margin-left: 1rem;
    }

    .searchbar-input {
        height: $searchbar-height !important;
        padding: 0 55px 0 60px;
        box-shadow: none !important;
        border-radius: 25px;
        background-color: $color-white;
        font-size: 16px !important;
        color: $color-list-font !important;
    }

    ion-icon {
        top: 0;
        left: 10px;
        height: 100%;
        padding-top: 5px;
        padding-left: 10px;
        color: transparent !important;

        svg {
            display: none !important;
        }
    }

    .searchbar-input-container .searchbar-search-icon {
        background: transparent url(/assets/svg/search-icon.svg) no-repeat center center;
    }

    button {
        height: $searchbar-height;
        padding-bottom: 10px;
        margin-right: 15px;
        color: $color-list-font !important;
    }
}

.worktime {
    .dx-field-item-label {
        padding: 10px 0 8px 0 !important;
        margin: 0 !important;
    }

    .dx-box-item .dx-box-item {
        margin: 0 !important;
    }
}

.dx-popup-wrapper > .dx-overlay-content {
    max-width: none !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;

    .dx-box-item {
        margin-bottom: 0 !important;
    }
}

.fullwidth-item {
    padding-left: 13px;
    padding-right: 13px;
}

.status-box {
    min-height: 32px;
    display: inline-block;
    padding: 3px 10px 7px 10px;
    border-radius: 4px;
    color: $color-white;
    background: $color-primary-green;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-tabs {
    .dx-tabpanel {
        .dx-multiview-item-content,
        .dx-multiview-wrapper {
            border: 0;
            box-shadow: none !important;
            padding: 0;
            background: transparent;
        }

        .dx-tabs-wrapper {
            display: flex !important;
            margin-bottom: 20px;

            @media (max-width: 1439px) {
                justify-content: space-between;
            }

            @media (max-width: 1110px) {
                justify-content: space-between;
                flex-direction: column;
            }
        }

        & > .dx-tabpanel-tabs {
            .dx-tabs,
            .dx-tab {
                box-shadow: none !important;
                padding-left: 0;
                padding-right: 0;
                background: transparent;
            }

            .dx-tab {
                position: relative;
                font-weight: 700;
                text-align: left;
                padding-left: 40px;
                margin-bottom: 10px;
                width: auto;
                font-size: 16px;

                @media (max-width: 1439px) {
                    padding-left: 30px;
                }

                &:not(:last-child) {
                    margin-right: 60px;

                    @media (max-width: 1439px) {
                        margin-right: 20px;
                    }
                }

                &::after,
                &::before {
                    position: absolute;
                    left: 0;
                    top: 12px;
                    display: block;
                    content: '';
                    width: 24px;
                    height: 24px;
                    border: 2px solid $color-secondary-white;
                    border-radius: 12px;
                }

                &::after {
                    display: none;
                    left: 5px;
                    top: 17px;
                    width: 14px;
                    height: 14px;
                    border-radius: 7px;
                    border: 0;
                    background: $color-primary-green;
                }

                &-selected {
                    &::before {
                        border-color: $color-primary-green;
                    }
                    &::after {
                        display: block;
                    }
                }
            }
        }
    }

    .search-icon {
        .dx-label-before {
            width: 60px !important;
            display: flex;
            justify-content: center;
            align-items: center;

            &::before {
                position: relative;
                display: block;
                width: 26px;
                height: 26px;
                background: transparent url(/assets/svg/search-icon.svg) no-repeat center center / contain;
                content: '' !important;
            }
        }
        .dx-texteditor-input {
            padding-left: 60px !important;
        }
        .dx-dropdowneditor-button {
            display: none;
        }
    }
}

.box-shadow {
    .dx-box-item-content{
        box-shadow: 0px 4px 4px rgba(31, 33, 37, 0.2);
        padding: 32px 30px;
        border-radius: 4px;

        .dx-box-item-content{
            box-shadow: none;
            padding: 0;
        }
    }
}

.dx-datagrid-filter-row {
    display: none !important;
}

.dx-header-row td {
    color: $color-secondary-grey !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

// endregion

.dx-scheduler-navigator-calendar {
    border-width: 0;
    height: 386px !important;
    margin: 12px 36px 19px;
    width: 364px !important;

    &.dx-calendar-view-decade,
    &.dx-calendar-view-century,
    &.dx-calendar-view-year {
        table tbody .dx-calendar-cell > span {
            font-size: 18px;
            height: 72px;
            padding-bottom: 4px;
        }
    }

    &.dx-calendar-view-month {
        table tbody .dx-calendar-cell > span {
            font-size: 14px;
            height: 40px;
            padding-bottom: 2px;
        }
    }

    .dx-calendar-navigator {
        height: 40px;

        .dx-calendar-navigator-next-view,
        .dx-calendar-navigator-previous-view {
            border-radius: 50%;
            height: 40px;
            margin: 0;
            width: 40px;

            .dx-icon {
                color: black;
            }
        }

        .dx-calendar-caption-button {
            border-color: transparent;
            left: 48px;
            right: 48px;

            .dx-button-text {
                font-size: 20px;
            }
        }
    }

    .dx-calendar-body {
        top: 56px;

        table {
            height: auto;
        }

        table th {
            box-shadow: inset 0 -1px 0 #e7e7e7;
            color: black;
            font-size: 16px;
            font-weight: 400;
            height: 57px;
            padding: 0 6px 16px;
            width: 100%;
        }

        tbody tr:first-child .dx-calendar-cell {
            padding-top: 16px;
        }

        .dx-calendar-cell {
            border-width: 0;
            box-shadow: none !important;
            padding: 1px 6px;

            &.dx-state-hover {
                box-shadow: none;
            }

            > span {
                align-items: center;
                border-radius: 4px;
                display: grid;
                line-height: 1.1;
                width: 100%;
            }

            &.dx-state-hover,
            &.dx-calendar-selected-date,
            &.dx-calendar-today {
                box-shadow: none;

                > span {
                    background-color: $color-primary-green;
                    color: white;
                }
            }
        }
    }
}

.dx-popover-wrapper {
    .dx-popover-arrow::after {
        border: 0 !important;
        background: $color-primary-black !important;
    }

    &.dx-position-right {
        .dx-popover-arrow::after {
            right: -4px;
        }
    }

    .dx-overlay-content {
        line-height: 100% !important;
        background: $color-primary-black;
        border-radius: 4px;
        border: 0;

        .dx-popup-content {
            background-color: white;
            padding: 4px 16px 8px 16px;;
            color: $color-white;
            font-size: 14px;
        }
    }
}

.dx-link-delete {
    &:before {
        display: block;
        width: 20px;
        height: 20px;
        content: '';
        background: transparent url('/assets/svg/delete-icon.svg') no-repeat center center / contain;
    }
}

.dx-link-edit {
    &:before {
        display: block;
        width: 20px;
        height: 20px;
        content: '';
        background: transparent url('/assets/svg/modify-icon.svg') no-repeat center center / contain;
    }
}

.margin-fix {
    .dx-group-colcount-2 {
        margin-left: -47px;
        margin-right: -47px;
    }
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item {
    &.dx-list-item-selected {
        background: $color-primary-green !important;

        &.dx-state-hover {
            background: $color-primary-green !important;
        }
    }
}

.dx-popup-content {
    .dx-scrollview-content {
        .dx-list-item:first-child {
            &.dx-state-disabled {
                position: relative;
                opacity: 1 !important;

                .dx-list-item-content {
                    font-weight: 700;
                    color: $color-primary-black !important;
                }
            }
        }
    }
}
