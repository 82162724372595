@import "src/theme/variables";

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus,
:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px $color-editor-background inset !important;
}

dxi-item,
.dx-box-item,
.dx-field {
    margin: 0 0 16px 0;

    .dx-texteditor.dx-editor-outlined {
        border: 0;
    }

    textarea {
        height: 120px;
    }

    input,
    textarea{
        padding: 24px 16px 8px 16px !important;
        background: $color-editor-background;
        font-size: 16px;
        border-radius: 8px;
    }

    .dx-texteditor-input-container {
        padding: 0 !important;
        background: $color-editor-background !important;
    }

    .dx-dropdowneditor-button {
        border: 0 !important;
        padding: 0 !important;
        width: 40px;
    }

    .dx-dropdowneditor-icon {
        width: 100%;
        background: $color-editor-background !important;
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        border: 0 !important;

        &::before {
            color: $color-primary-green;
            margin-left: -13px;
        }
    }

    .dx-datebox-time{
        .dx-dropdowneditor-icon {
            &::before {
                width: 20px;
                height: 20px;
                content: '';
                background: transparent url(/assets/svg/form-clock-icon.svg) no-repeat center center / contain;
            }
        }
    }

    .dx-datebox {
        input {
            padding-right: 0 !important;
        }
    }

    .dx-numberbox,
    .dx-textbox {
        .dx-invalid-message.dx-overlay {
            top: -55px;
            left: 1px;

            .dx-invalid-message > .dx-overlay-content {
                position: relative;
                top: 1px !important;
                left: 2px !important;
                width: calc(100% - 3px) !important;
                padding: 6px 15px 2px 15px;
                color: #F27100;
                font-size: 14px;
                white-space: nowrap;
                background: $color-editor-background;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                transform: none !important;
            }
        }
    }

    .dx-field-value {
        position: relative;

        ion-icon {
            position: absolute;
            top: 18px;
            left: 0;
            z-index: 10;
            width: 54px;
            text-align: center;
            font-size: 20px;

            & + .dx-textbox  {

                input,
                textarea{
                    padding-left: 54px !important;
                }

                .dx-texteditor-label {
                    .dx-label-before {
                        min-width: 52px !important;
                    }
                }

                .dx-invalid-message.dx-overlay {
                    .dx-invalid-message > .dx-overlay-content {
                        width: calc(100% - 40px - 54px) !important;
                        left: 54px !important;
                        padding-left: 0;
                    }
                }
            }
        }
    }

    .dx-texteditor-label {
        font-size: 14px;
    }

    .dx-textarea {
        .dx-texteditor-input {
            border: 1px solid $color-secondary-white !important;
        }
    }

    .has-placeholder .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty .dx-texteditor-label,
    .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-focused .dx-texteditor-label,
    .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-invalid.dx-state-focused .dx-texteditor-label,
    .dx-editor-outlined.dx-texteditor-with-floating-label .dx-texteditor-label.dx-state-focused,
    .dx-editor-outlined.dx-texteditor-with-floating-label .dx-texteditor-label {
        width: calc(100% - 1px);
        border: 1px solid $color-secondary-white;
        border-radius: 8px;

        .dx-label-before {
            min-width: 12px;
        }

        .dx-label,
        .dx-label-before,
        .dx-label-after {
            border: 0 !important;
        }

        .dx-label {
            color: $color-secondary-grey;
            width: 100%;

            span {
                display: block;
                width: 100%;
                height: 25px !important;
                padding-top: 4px;
                font-size: 14px !important;
                background: $color-editor-background;
                transform: translate(0, 0) !important;
            }
        }
    }

    .has-placeholder .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty:not(.dx-state-focused) .dx-texteditor-label {
        .dx-label {
            span {
                top: 10px;
                transition: none !important;
            }
        }
    }

    .has-placeholder .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty .dx-texteditor-label {
        .dx-label {
            span {
                transition: none !important;
            }
        }
    }

    .dx-editor-outlined.dx-texteditor-with-floating-label.dx-lookup-empty .dx-texteditor-label .dx-label span,
    .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty .dx-texteditor-label .dx-label span,
    .dx-editor-outlined.dx-texteditor-with-floating-label.dx-lookup-empty.dx-invalid .dx-texteditor-label .dx-label span,
    .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-invalid .dx-texteditor-label .dx-label span {
        transform: translate(0, -7px) !important;
        font-size: 16px !important;
        line-height: 1.3;
    }

    .dx-box-item .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-dropdowneditor-active .dx-texteditor-label .dx-label span {
        transform: translate(0, 0) !important;
        font-size: 14px !important;
    }

    .dx-textarea.dx-editor-outlined.dx-texteditor-with-floating-label.dx-lookup-empty .dx-texteditor-label .dx-label span,
    .dx-textarea.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty .dx-texteditor-label .dx-label span,
    .dx-textarea.dx-editor-outlined.dx-texteditor-with-floating-label.dx-lookup-empty.dx-invalid .dx-texteditor-label .dx-label span,
    .dx-textarea.dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-invalid .dx-texteditor-label .dx-label span {
        transform: translate(0, 8px) !important;
    }

    .dx-invalid.dx-texteditor.dx-editor-filled.dx-show-invalid-badge .dx-texteditor-input-container::after,
    .dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge .dx-texteditor-input-container::after,
    .dx-invalid.dx-texteditor.dx-editor-underlined.dx-show-invalid-badge .dx-texteditor-input-container::after {
        top: 28px;
        width: 24px;
        height: 24px;
        margin-top: -11px;
        margin-right: 10px;
        background: $color-editor-background url(/assets/svg/form-error-icon.svg) no-repeat center center / contain;
        content: '';
        z-index: 2000 !important;
    }
}

.dx-layout-manager .dx-field-item:not(.dx-first-row) {
    padding-top: 0 !important;
}

.dx-checkbox {
    &-checked {
        .dx-checkbox-icon {
            border-color: $color-primary-green !important;
            background-color: $color-primary-green !important;
            font: 14px / 0 $font-family;

            &:before {
                content: '';
                width: 13px;
                height: 13px;
                background: transparent url("/assets/svg/tick-icon-white.svg") no-repeat center center / contain;
            }
        }
    }

    &-icon {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        color: $color-white;
        border: 2px solid $color-secondary-white !important;
        background-color: $color-white !important;

    }
}

.color-select-box-popup {
    padding: 82px 24px 24px 32px !important;
    width: 412px !important;
    overflow: visible !important;

    .dx-scrollable-container {
        overflow: visible !important;
    }

    .dx-scrollview-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &:before {
            display: none;
        }

        .dx-list-item {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            margin: 0 8px 8px 0;

            .dx-template-wrapper {
                padding: 0;
            }

            .color-box {
                width: 40px;
                height: 40px;
                border-radius: 8px;
            }

        }
    }
}

.field-color-box {
    width: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 8px;
    background: transparent url(/assets/svg/modify-icon.svg) no-repeat center center / 50% 50%;

    & + .dx-widget {
        display: none !important;
    }
}

.checkbox {
    .dx-field-item-label {
        display: block !important;
    }

    .dx-layout-manager .dx-label-h-align.dx-flex-layout .dx-field-item-content {
        flex-grow: 0;
        width: auto;
        margin-right: 16px;
    }
}

.custom-tag-box {
    position: relative;

    &-container {
        position: relative;
        height: 100%;

        &:before {
            position: absolute;
            top: 53px;
            left: 47px;
            right: 47px;
            margin: auto;
            display: block;
            content: '';
            border: 1px solid $color-secondary-white;
            background: $color-editor-background;
            border-radius: 8px;
        }
    }

    .dx-tagbox:not(.dx-tagbox-single-line) .dx-texteditor-input-container {
        position: absolute;
        top: 56px;
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 0;
        padding: 3px 0 !important;
        background: transparent !important;
    }

    .dx-texteditor-container.dx-native-click {
        overflow: visible;
        height: 56px;

        .dx-placeholder {
            display: none !important;
        }
    }

    .dx-dropdowneditor-input-wrapper {
        overflow: visible;
    }

    .dx-texteditor.dx-editor-outlined {
        position: relative;
        top: -56px;
        z-index: 100;
        background: transparent !important;
    }

    .dx-tag {
        width: 100%;
    }

    .dx-tag-content {
        background: transparent;
        padding: 3px 0 3px 16px;
        margin: 0;
    }

    .dx-tag-remove-button {
        display: none;
    }

    .dx-texteditor-input {
        display: none;
    }

    .dx-button {
        position: absolute !important;
        top: -60px;
        right: 0;
        z-index: 101;
        pointer-events: none;

        &-content {
            padding-right: 0 !important;
        }
    }

    &.small-label {
        .dx-button {
            top: -41px;
        }
    }

    &.extended-click-area {
        .select-drop-down {
            position: absolute;
            top: -40px;
            bottom: 0;
            height: 100% !important;
            width: 100%;

        }
        dx-scroll-view {
            margin-top: 60px;
            background: $color-white !important;
        }

        .select-list {
            position: relative;
            z-index: 100;
            background: $color-white;
        }

        .add-label {
            position: absolute;
            top: 10px;
            bottom: 40px;
            left: 10px;
            right: 10px;
            z-index: 1;
            height: 20px;
            margin: auto;
            text-align: center;
            pointer-events: none;

            ion-icon {
                margin-right: 16px;
            }
        }
    }
}

.not-specified-label {
    position: absolute;
    top: 10px;
    left: 16px;
    right: 10px;
    z-index: 1;
    height: 20px;
    margin: auto;
    text-align: left;
    pointer-events: none;
    font-size: 16px;
}

.custom-select-list {
    @extend .custom-tag-box;

    .dx-dropdowneditor-button,
    .dx-dropdowneditor-icon,
    .dx-texteditor-input-container {
        background: transparent !important;

        &:before {
            display: none;
        }
    }

    .dx-list-item {
        padding: 0;
        line-height: 100% !important;

        .dx-item-content {
            line-height: 25px !important;
            padding: 0px 10px;
            font-size: 16px;
        }
    }

    .dx-texteditor.dx-editor-outlined + dx-scroll-view {
        position: relative;
        top: -55px;

        border: 1px solid $color-secondary-white;
        background: $color-editor-background;
        border-radius: 8px;
    }

    .dx-list-static-delete-button-container {
        display: none !important;
    }
}

.unit-label-container .dx-item-content {
    position: relative;

    & > .dx-box-item:last-child{
        display: flex;
        min-width: 0px;
        flex: 1 1 0px;
        position: absolute;
        right: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
        justify-content: center;
        align-items: center;
    }
}

.select-drop-down {
    height: 56px !important;
}

app-appointment-select-list .dx-dropdowneditor-icon::before,
.dx-datebox-calendar .dx-dropdowneditor-icon::before {
    display: none !important;
}

app-appointment-select-list .select-drop-down,
.dx-datebox-calendar {
    position: relative;

    &::after {
        position: absolute;
        top: 18px;
        right: 18px;
        display: block;
        width: 20px;
        height: 20px;
        content: '';
        background: transparent url(/assets/svg/calendar-icon-green.svg) no-repeat center center / contain;
        pointer-events: none;
    }
}


[readonly] {
    cursor: default !important;
}

.facility-item-wrapper {
    width: 100%;

    .facility-extra,
    .facility-item {
        display: flex;
        justify-content: space-between;
    }

    .facility-extra {
        flex-direction: column;
        font-size: 12px;
        padding-left: 30px;
        line-height: 20px;

        .facility-extra-item {
            display: flex;
            justify-content: space-between;
        }

    }
}

.dx-dropdowneditor-icon::before {
    content: '';
    width: 16px;
    height: 16px;
    background: transparent url(/assets/svg/chevron-down-icon.svg) no-repeat center center / contain;
    margin-left: -16px;
}

.has-placeholder {
    .dx-item {
        margin-bottom: 0;
    }

    .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-readonly {
        &.dx-state-hover {
            .dx-placeholder {
                &::before {
                    display: inline-block !important;
                    vertical-align: middle;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    content: attr(DATA-DX_PLACEHOLDER) !important;
                    pointer-events: none;
                    white-space: nowrap;
                }
            }
        }
        .dx-placeholder {
            display: block;
            top: 10px;
            left: 6px;
        }
    }

}
