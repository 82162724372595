.dx-button {
    position: relative;
    border-radius: 8px;

    &.normal-font {
        .dx-button-content {
            .dx-button-text {
                font-weight: 400 !important;
            }
        }
    }

    .dx-button-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .dx-button-text {
            font-weight: 700;
            font-size: 16px;
        }

        ion-icon {
            position: relative;
            top: 3px;
            font-size: 20px;

            &.icon-left {
                margin-right: 10px;
            }

            &.icon-right {
                margin-left: 10px;
            }
        }
    }
}

.dx-button-danger {
    @extend .dx-button;
    background-color: $color-danger !important;
}

.dx-button-primary {
    @extend .dx-button;

    background-color: $color-primary-green !important;
    border-color: $color-primary-green !important;

    &:hover,
    .dx-state-hover {
        background-color: $color-secondary-green !important;
        border-color: $color-secondary-green !important;

        .dx-button {
            background-color: $color-primary-green !important;
        }
    }

    .dx-button-content {
        .dx-button-text {
            color: $color-white !important;
        }
    }

    &.dx-button-mode-contained .dx-icon {
        color: $color-white !important;
    }

    .dx-button-content {
        padding: 9px 18px 13px 18px !important;
    }

    .dx-button {
        position: relative;
        top: 2px;
        right: -10px;
        width: 32px;
        height: 32px;
        margin-top: -4px;
        margin-bottom: -4px;
        border: 0;
        padding: 0;
        background: $color-secondary-green !important;
        color: $color-primary-white !important;

        &:hover,
        .dx-state-hover {
            background: $color-white !important;
            color: $color-secondary-green !important;
        }

        .dx-button-content {
            padding: 0 !important;

            ion-icon {
                font-size: 15px;
                margin-top: -4px;
            }
        }
    }
}

.dx-button-secondary {
    @extend .dx-button;

    background-color: $color-white !important;
    border-color: $color-secondary-white !important;

    &:hover,
    .dx-state-hover {
        background-color: $color-white !important;
        border-color: $color-primary-black !important;
    }

    .dx-button-content {
        padding: 9px 18px 13px 18px !important;
    }
}

.dx-button-orange {
    @extend .dx-button;

    background-color: $color-primary-orange !important;
    border-color: $color-primary-orange !important;

    &:hover,
    .dx-state-hover {
        background-color: $color-secondary-orange !important;
        border-color: $color-secondary-orange !important;
    }

    .dx-button-content {
        .dx-button-text {
            color: $color-white !important;
        }
    }

    &.dx-button-mode-contained .dx-icon {
        color: $color-white !important;
    }

    .dx-button-content {
        padding: 9px 18px 13px 18px !important;
    }
}


.dx-button-mode-text {
    border: transparent !important;

    &:hover,
    .dx-state-hover {
        background-color: transparent !important;
        border-color: transparent !important;
    }
}
